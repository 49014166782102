import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import './style.css'; 
import loginImage from '../images/login.png'; 
import logoImage from '../images/login2.png'; 
import mask from '../images/Mask.svg'; 



const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        if (error) setError(''); // Clear error when the user starts typing
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        if (error) setError(''); // Clear error when the user starts typing
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/login`, {
                userName: username,
                password: password,
            });

            const user = response.data;
            


            if (user && user.token) {
                // Store token and role in local storage
                localStorage.setItem('token', user.token);
                localStorage.setItem('userName', user.userName); 
                localStorage.setItem('email', user.email);              
                localStorage.setItem('employeeName', user.employee.name);
                localStorage.setItem('employeeTitle', user.employee.title);
                localStorage.setItem('departmentName', user.employee.departmentName);
                localStorage.setItem('sectionName', user.employee.sectionName);
                localStorage.setItem('position', user.employee.position);

                // Redirect based on the user's title
                if (user.employee.departmentName === 'ادخال البيانات') {
                    navigate('/form');
                } else {
                    navigate('/dashboard/income');
                }
            } else {
                setError('Invalid username or password');
            }
        } catch (error) {
            console.error('Login error:', error);
            setError('Invalid username or password');
        }
    };

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible); // Toggle the password visibility
    };


  


    return (
        <div className="login-container">
            <div className="login-image">
                <img src={mask} alt="Login Cover" />
            </div>
            <div className="login-form">
                <img src={logoImage} alt="Logo" className="login-logo" />
                <h1>نظام البريد الالكتروني</h1>
                <p>يرجى إدخال معلومات الحساب لاستخدام النظام</p>
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        placeholder="اسم المستخدم"
                        className="login-input"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                    />
                    <div className="password-field">
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            placeholder="كلمة المرور"
                            className="login-input"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <span onClick={togglePasswordVisibility} className="eye-icon">
                            {passwordVisible ? <FaEyeSlash size={20} /> : <FaEye size={20} />}
                        </span>
                    </div>
                    {error && <h5 className="error-message">{error}</h5>}
                    <div className="login-button-container">
                        <button type="submit" className="login-button">تسجيل دخول</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
