import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './income.css';

import blue from '../images/blue.svg';
import normal from '../images/normal.svg';
import purple from '../images/purple.svg';
import red from '../images/red.svg';
import secret from '../images/secret.svg';

import archImg1 from '../images/archive.svg';
import Card from './card';



const Archive = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [data, setData] = useState([]);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [error, setError] = useState('');

    

    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/archived`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setData(response.data);
            } catch (err) {
                setError('Failed to fetch data. Please try again.');
                console.error('Fetch error:', err);
            }
        };

        fetchData();
    }, []);

    const getPriorityInArabic = (priority) => priorityMap[priority] || priority;


 useEffect(() => {
    const fetchPdf = async () => {
        if (selectedItem && selectedItem.documentUrl) {
            try {
                const token = localStorage.getItem('token');
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/file?fileUrl=${selectedItem.documentUrl}`, {
                    // params: { fileUrl: encodeURIComponent(selectedItem.documentUrl) },
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/pdf',
                    },
                    responseType: 'blob',
                });

                // Create a URL for the PDF blob and set it to state
                const url = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                setPdfUrl(url);
            } catch (err) {
                console.error('Error fetching PDF:', err);
            }
        }
    };

    fetchPdf();
}, [selectedItem]);


  

const priorityMap = {
    'Secret': 'سري',
    'Normal': 'عادي',
    'Urgent': 'عاجل',
    'VeryUrgent': 'عاجل جدا',
    'PersonalSecret': 'سري وشخصي'
};


const handleRowClick = (item) => {
    setSelectedItem(item);
  
};




const getPriorityInEnglish = (priority) => {
    const priorityMapReversed = Object.fromEntries(
        Object.entries(priorityMap).map(([key, value]) => [value, key])
    );
    return priorityMapReversed[priority] || priority;
};



   

   
    const handleBackClick = () => {
        setSelectedItem(null);
    };




  
   
      
 

  

  


    const getStatusIcon = (status) => {
        switch (status) {
            case 'سري':
                return blue;
            case 'عادي':
                return normal;
            case 'عاجل':
                return purple;
            case 'عاجل جدا':
                return red;
            case 'سري وشخصي':
                return secret;
            default:
                return null;
        }
    };



 
    

    return (
        <div className="income-container">
            {/* Conditional Rendering: Table or Detail View */}
            {selectedItem ? (
                <div className="detail-container">
                  <div className="header2">
    <div className="header-content">
    <img src={archImg1} 
                    className="header-icon" />
        <h1 className="header-text2">الارشيف</h1>
    </div>
    <div className="actions1">
   
       
       
        
       
    </div>
</div>
                    <div className="detail-content">
                        <div className="detail-info">
                        <img src={getStatusIcon(getPriorityInArabic(selectedItem.priority))} alt={selectedItem.priority} className="status-icon-large" />
                            <span className='statuswithflag'>{getPriorityInArabic(selectedItem.priority)}</span>
    <p><span className="detail-title">المرسل:</span> <span className="detail-answer">{selectedItem.sender}</span></p>
    <p><span className="detail-title">الموضوع:</span> <span className="detail-answer">{selectedItem.topic}</span></p>
    <p><span className="detail-title">العدد:</span> <span className="detail-answer">{selectedItem.number}</span></p>
    <p><span className="detail-title">التاريخ:</span> <span className="detail-answer">{selectedItem.date}</span></p>
    
    
    
                        </div>
                        <div className='pdf'>
                        {pdfUrl ? (
                                <iframe src={pdfUrl} width="100%" height="500px" />
                            ) : (
                                <p>Loading PDF...</p>
                            )}
                            </div> <br></br>
                         <div className='line'></div>
                        
                        <div className="actions2">
                           
                           
                        
                        <button className="back-button" onClick={handleBackClick}>الـــــــرجوع</button>

                        
                        </div>
                    </div>

                    <Card formId={selectedItem.id}/>
                     {/* Edit Overlay */}
                    
                 {/* Forward Overlay */}
                
                </div>
            ) : (
            
               <>
                    {/* Header */}
                    <div className="header">
                    <img src={archImg1}
                    className="header-icon" />
                        <h1 className="header-text">الارشيف</h1>
                    </div>
                    
                    {/* Search Bar */}
                    <div className="search-bar">
                        <button className="search-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M9.5 3c-3.6 0-6.5 2.9-6.5 6.5s2.9 6.5 6.5 6.5c1.4 0 2.7-.4 3.8-1.2l5.7 5.7 1.4-1.4-5.7-5.7c.8-1.1 1.2-2.4 1.2-3.8 0-3.6-2.9-6.5-6.5-6.5zm0 2c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5-4.5-2-4.5-4.5 2-4.5 4.5-4.5z"/>
                            </svg>
                        </button>
                        <input type="text" placeholder="بحث" className="search-input" />
                        <button className="filter-button">
                            <svg width="20" height="20" viewBox="0 0 24 24">
                                <path d="M3 4c-1.1 0-1.99.9-1.99 2l-.01 2c0 .71.37 1.36.96 1.74l5.49 3.26v8.5c0 .38.21.72.55.88.14.06.28.12.43.12.24 0 .48-.07.68-.22l3-2c.26-.18.42-.47.42-.78v-6.5l5.48-3.26c.6-.38.97-1.02.97-1.74v-2c0-1.1-.9-2-2-2h-16zm0 2h16v2l-6 3.57v6.93l-2 1.33v-8.26l-6-3.57v-2z"/>
                            </svg>
                        </button>
                    </div>

                    {/* Table */}
                    <table className="income-table">
                    <tbody>
                            {data.map(item => (
                                <tr 
                                    key={item.id} 
                                    onClick={() => handleRowClick(item)} 
                                    className="clickable-row"
                                >
                                    <td className="status-cell">
                                    <img src={getStatusIcon(getPriorityInArabic(item.priority))} alt="Status Icon" className='status-icon' /> 
                                    <span>{getPriorityInArabic(item.priority)}</span>
                                    </td>
                                    <td className="sender-cell">{item.sender}</td>
                                    <td className="subject-cell">{item.topic}</td>
                                    <td className="section-cell">
                                        <div className="section-badge">{item.note}</div>
                                    </td>
                                    <td className="date-cell">{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    
                </>
            )}
        </div>
    );
};

export default Archive;