import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation , useNavigate } from 'react-router-dom';
import './dashboard.css';
import logoImage from '../images/login2.png'; 
import fileImg from '../images/file.png';
import downImg from '../images/download.png';
import upImg from '../images/upload.svg';
import archImg from '../images/archive.png';
import { FaUsers } from "react-icons/fa6";
import { LuFileClock } from "react-icons/lu";
import { MdLogout } from 'react-icons/md';
import { LuFileDown } from "react-icons/lu";
import { LuFileWarning } from "react-icons/lu";
import { LuUserCircle2 } from "react-icons/lu";
import profileImage from '../images/profile.webp';
import { IoNotificationsSharp } from "react-icons/io5";






import axios from 'axios';


const Dashboard = () => {
    const [selectedOption, setSelectedOption] = useState("");
    const navigate = useNavigate();

    const [incomeCount, setIncomeCount] = useState(0);
    const [outboxCount, setOutboxCount] = useState(0);
    const [archiveCount, setArchiveCount] = useState(0);
    const [notcompleteCount, setNotcompleteCount] = useState(0);
    const [delatoryCount, setDelatoryCount] = useState(0);

    const location = useLocation();
    
    const handleChange = (e) => {
        const value = e.target.value;
        setSelectedOption(value);
        if (value === 'internalMemo') {
            navigate('/internalMemo', { state: { employeeName, employeeTitle } });
        } else if (value === 'issuedBook') {
            navigate('/issuedBook' , { state: { employeeName, employeeTitle } } );
        }
    };


    const [employeeName, setEmployeeName] = useState('');
    const [employeeTitle, setEmployeeTitle] = useState('');
    
    useEffect(() => {
        // Retrieve data from localStorage
        const storedName = localStorage.getItem('employeeName');
        const storedTitle = localStorage.getItem('employeeTitle');
        if (storedName) setEmployeeName(storedName);
        if (storedTitle) setEmployeeTitle(storedTitle);
    }, []);




    useEffect(() => {
        const fetchCounts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/counts`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                });
                const data = response.data;
                setIncomeCount(data.import);
                setOutboxCount(data.export);
                setArchiveCount(data.archive);
                setNotcompleteCount(data.inProgress);
                setDelatoryCount(data.delatory);
            } catch (error) {
                console.error('Error fetching counts:', error);
            }
        };
        
        fetchCounts();
    }, []);


    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token'); 
    
            if (token) {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/logout`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
                });
    
                localStorage.removeItem('token');
                localStorage.removeItem('employeeName');
                localStorage.removeItem('employeeTitle');
                localStorage.removeItem('departmentName');
                localStorage.removeItem('position');
    
                navigate('/');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }
    };
    

    const handleLinkClick = (targetPath) => {
        if (location.pathname === targetPath) {
          // If already on the target path, refresh the page
          window.location.reload();
        }
        // Otherwise, React Router will navigate to the new route without a full page reload
      };


    return (
        <div className="dashboard-container">
            <div className="top-bar">
                <div className="logo-container">
                    <img src={logoImage} alt="Logo" className="logo" />
                </div>
                <div className="user-profile">
                    <img src={profileImage} alt="User Profile" className="profile-pic" />
                    <div className="user-info">
                    <p>{employeeName || 'الاسم الثلاثي'}</p>
                    <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="side-menu">
                    <div className="memo-select-container">
                        <select 
                            id="memo-select" 
                            value={selectedOption} 
                            onChange={handleChange} 
                            className="memo-select"
                        >
                            <option value="" disabled hidden>انشاء مذكرة</option>
                            <option value="internalMemo">إنشاء مذكرة داخلية</option>
                            <option value="issuedBook">إنشاء كتاب صادر</option>
                        </select>
                    </div><br></br>
                    <div>
                        <ul className="menu-list">
                            <li>
                                <Link 
                                    to="income" 
                                    className={`menu-link ${location.pathname === '/dashboard/income' ? 'active' : ''}`}
                                              onClick={() => handleLinkClick('/dashboard/income')}

                                >
                                    <img src={downImg} alt="Icon" className="menu-icon" />
                                    الوارد
                                    <span className="menu-count">{incomeCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="outbox" 
                                    className={`menu-link ${location.pathname === '/dashboard/outbox' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/outbox')}

                                >
                                    <img src={upImg} alt="Icon" className="menu-icon" />
                                    الصادر
                                    <span className="menu-count">{outboxCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="archive" 
                                    className={`menu-link ${location.pathname === '/dashboard/archive' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/archive')}

                                >
                                    <img src={archImg} alt="Icon" className="menu-icon" />
                                    الأرشيف
                                    <span className="menu-count">{archiveCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="notcomplete" 
                                    className={`menu-link ${location.pathname === '/dashboard/notcomplete' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/notcomplete')}

                                >
                                    <LuFileClock className="menu-icon" />
                                    قيد الانجاز
                                    <span className="menu-count">{notcompleteCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="delatory" 
                                    className={`menu-link ${location.pathname === '/dashboard/delatory' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/delatory')}

                                >
                                    <LuFileWarning className="menu-icon" />
                                     المتلكئ
                                    <span className="menu-count">{delatoryCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="notification" 
                                    className={`menu-link ${location.pathname === '/dashboard/notification' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/notification')}

                                >
                                    <IoNotificationsSharp className="menu-icon" />
                                     التبليغات
                                    <span className="menu-count">{delatoryCount}</span>
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="users" 
                                    className={`menu-link ${location.pathname === '/dashboard/users' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/users')}

                                >
                                    <FaUsers className="menu-icon" />
                                    
                                    المستخدمين
                                    {/* <span className="menu-count">{outboxCount}</span> */}
                                </Link>
                            </li>
                            <li>
                                <Link 
                                    to="userinfo" 
                                    className={`menu-link ${location.pathname === '/dashboard/userinfo' ? 'active' : ''}`}
                                    onClick={() => handleLinkClick('/dashboard/userinfo')}

                                >
                                    <LuUserCircle2  className="menu-icon" />
                                    
                                    الحساب الشخصي
                                    {/* <span className="menu-count">{outboxCount}</span> */}
                                </Link>
                            </li>
                            
                            <li className=''>
                                
                                <Link 
                                   Link to="/" onClick={handleLogout} 
                                    className={`menu-link ${location.pathname === '/users' ? 'active' : ''}`}
                                >
                                    <MdLogout className="menu-icon" />
                                    
                                    تسجيل الخروج
                                    {/* <span className="menu-count">{outboxCount}</span> */}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="content-wrapper">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
