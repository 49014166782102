import React, { useEffect, useState } from 'react';
import { IoNotificationsSharp } from "react-icons/io5";
import pencil from '../images/pencil.svg';
import './notification.css';
import axios from 'axios';

const Notification = () => {
  const [myNotifications, setMyNotifications] = useState([]);
  const [Notifications, setNotifications] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const token = localStorage.getItem('token');

  const handleRowClick = (item) => {
    setSelectedItem(item);
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/getmynoti`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setMyNotifications(response.data);
        } else {
          setMyNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications();
  }, [token]);

  useEffect(() => {
    const fetchNotifications2 = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/general/getnotifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (Array.isArray(response.data)) {
          setNotifications(response.data);
        } else {
          setNotifications([]);
        }
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchNotifications2();
  }, [token]);

  return (
    <div className="user-list-container">
      {!selectedItem ? (
        <>
          <div className="header2">
            <div className="header-content">
              <IoNotificationsSharp alt="Icon" className="header-icon" />
              <h1 className="header-text2">التبليغات</h1>
            </div>
            <div className="actions1">
              <button className="edit-button" onClick={() => {}}>
                <img src={pencil} alt="add" className="button-icon" />
                اضافة تبليغ
              </button>
            </div>
          </div>

          {/* Table for "My Notifications" */}
          <div className="table-section1">
            <h2 className="table-title">التبليغات الخاصة بي</h2>
            <table className="notification-table">
              <thead>
                <tr>
                  <th>المرسل</th>
                  <th>الموضوع</th>
                  <th>تاريخ البدء</th>
                  <th>تاريخ الانتهاء</th>
                </tr>
              </thead>
              <tbody>
                {myNotifications.length > 0 ? (
                  myNotifications.map((notification, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(notification)}
                      className={`clickable-row ${selectedItem?.id === notification.id ? 'selected-row' : ''}`}
                    >
                      <td>{notification.owner.name}</td>
                      <td>{notification.topic}</td>
                      <td>{new Date(notification.startDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                      <td>{new Date(notification.endDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">لا توجد تبليغات</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {/* Table for "Notifications" */}
          <div className="table-section1">
            <h2 className="table-title">التبليغات</h2>
            <table className="notification-table">
              <thead>
                <tr>
                  <th>المرسل</th>
                  <th>الموضوع</th>
                  <th>تاريخ البدء</th>
                  <th>تاريخ الانتهاء</th>
                </tr>
              </thead>
              <tbody>
                {Notifications.length > 0 ? (
                  Notifications.map((notification, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(notification)}
                      className={`clickable-row ${selectedItem?.id === notification.id ? 'selected-row' : ''}`}

                    >
                      <td>{notification.owner.name}</td>
                      <td>{notification.topic}</td>
                      <td>{new Date(notification.startDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                      <td>{new Date(notification.endDate).toLocaleString('ar-GB', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        hour12: true
                      })}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">لا توجد تبليغات</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        
        <div className="selected-item-details">
           <div className="header2">
            <div className="header-content">
              <IoNotificationsSharp alt="Icon" className="header-icon" />
              <h1 className="header-text2">التبليغات</h1>
            </div>
            
            
          </div>
          <h2>تفاصيل التبليغ</h2>
          <div className="details-grid">
            <div className="grid-item">
              <label>المرسل:</label>
              <input type="text" value={selectedItem.owner.name} readOnly />
            </div>
            <div className="grid-item">
              <label>الموضوع:</label>
              <input type="text" value={selectedItem.topic} readOnly />
            </div>
            
            <div className="grid-item">
              <label>إلى:</label>
              <input type="text" value={selectedItem.to.join(', ')} readOnly />
            </div>
            <div className="grid-item">
              <label>تاريخ البدء:</label>
              <input type="text" value={new Date(selectedItem.startDate).toLocaleString('ar-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })} readOnly />
            </div>
            <div className="grid-item">
              <label>تاريخ الانتهاء:</label>
              <input type="text" value={new Date(selectedItem.endDate).toLocaleString('ar-GB', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
              })} readOnly />
            </div>
            <div className="grid-item">
              <label>التفاصيل:</label>
              <textarea value={selectedItem.details} readOnly />
            </div>
          </div>

          <button onClick={() => setSelectedItem(null)} className="back-button">رجوع</button>
        </div>
      )}
    </div>
  );
};

export default Notification;
