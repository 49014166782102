import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import './internalMemo.css';
import logoImage from '../images/login2.png'; 
import issued from '../images/issued.svg'; 
import print from '../images/printer.svg'; 
import profileImage from '../images/profile.webp';


const IssuedBook = () => {
  const [topic, setTopic] = useState('');
  const [date, setDate] = useState('');
  const [paragraph, setParagraph] = useState('');

  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  

  const routerLocation = useLocation(); // Renamed the variable to avoid conflict

  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const [selectedRecipients, setSelectedRecipients] = useState([]);
  const employeeName = routerLocation.state?.employeeName || '';
  const employeeTitle = routerLocation.state?.employeeTitle || '';

  // const recipientsList = [
  //   'Recipient 1',
  //   'Recipient 2',
  //   'Recipient 3',
  //   'Recipient 4',
  //   'Recipient 5',
  // ];

  // const toggleDropdown = () => {
  //   setDropdownOpen(!isDropdownOpen);
  // };

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     setSelectedRecipients([...selectedRecipients, value]);
  //   } else {
  //     setSelectedRecipients(selectedRecipients.filter((recipient) => recipient !== value));
  //   }
  // };


  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setError('');  // Clear the error message when the user starts typing
    setSuccess(false);  // Clear the success message when the user starts typing
  };


    return (
        <div className="internalM-container">
        
         <div className="top-bar">
                            <div className="logo-container">
                                <img src={logoImage} alt="Logo" className="logo" />
                            </div>
                            <div className="user-profile">
                                <img src={profileImage} alt="User Profile" className="profile-pic" />
                                <div className="user-info">
                                <p>{employeeName || 'الاسم الثلاثي'}</p>
                                <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
                                </div>
                            </div>
                        </div>
                        <div className="document-content">
                          <h3>عرض توضيحي</h3>
                        <img src={issued} alt="Logo" className="Memo" />
                        <div className="text-overlay">
          <h5 className='topicover2'> م/{topic}</h5>
          <p className='dateover'> {date}</p>
          <textarea className="noteover2" value={paragraph} readOnly />
          </div>
    
                            </div>
                    
                        <div className="memo-form">
          <h2>انشاء كتاب صادر</h2>
          <div className="formM-group">
            <label className='labelM'>الى</label>
            <input type="text" />
  {/* <div className="multi-select-dropdown1">
            <button className="dropdown-toggle1" onClick={toggleDropdown}>
              <svg className={`arrow-icon1 ${isDropdownOpen ? 'open1' : ''}`} width="15" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 10l5 5 5-5H7z" fill="#000" />
              </svg>
            </button>
            {isDropdownOpen && (
              <div className="dropdown-menu1">
                {recipientsList.map((recipient, index) => (
                  <label key={index} className="dropdown-item1">
                    <input
                      type="checkbox"
                      value={recipient}
                      checked={selectedRecipients.includes(recipient)}
                      onChange={handleCheckboxChange}
                    />
                    {recipient}
                  </label>
                ))}
              </div>
            )}
          </div>   */}
          </div>     
          <div className="formM-group">
            <label className='labelM'>الموضوع</label>
            <input
      type="text"
      value={topic}
      onChange={handleInputChange(setTopic)}
      required
    />
          </div>
          {/* <div className="formM-group">
            <label className='labelM'>العدد</label>
            <input type="text"  />
          </div> */}
          <div className="formM-group">
            <label className='labelM'>التاريخ</label>
            <input
      type="text"
      value={date}
      onChange={handleInputChange(setDate)}
      required
    />
          </div>
          <div className="formM-group">
            <label className='labelM'>النص</label>
            <textarea
      placeholder="اكتب شيئاً ..."
      value={paragraph}
      onChange={handleInputChange(setParagraph)}
      dir="rtl"
      required
    ></textarea>          </div>
          <div className="Memo-buttons">
          <button type="submit" className="send-button">ارسال</button>

            <button className="print-button"> <img src={print} alt="forward" className="print-icon" /> طباعة</button>
            
          </div>
        </div>
        
        
       
    
        </div>
        
       
      )
}

export default IssuedBook;