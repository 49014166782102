import React, { useEffect, useState } from 'react';
import './users.css';
import axios from 'axios';



import { LuUserCircle2 } from "react-icons/lu";


const Userinfo = () => {
    const [userName , setUserName] = useState('');
    const [email , setEmail] = useState('');
    const [employeeName, setEmployeeName] = useState('');
    const [employeeTitle, setEmployeeTitle] = useState('');
    const [departmentName, setDepartmentName] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [position, setPosition] = useState('');
    const [showOverlay, setShowOverlay] = useState(false);
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [formError, setFormError] = useState('');


    useEffect(() => {
        // Retrieve data from localStorage
        setUserName(localStorage.getItem('userName') || '');
        setEmail(localStorage.getItem('email') || '');        
        setEmployeeName(localStorage.getItem('employeeName') || '');
        setEmployeeTitle(localStorage.getItem('employeeTitle') || '');
        setDepartmentName(localStorage.getItem('departmentName') || '');
        setSectionName(localStorage.getItem('sectionName') || '');
        setPosition(localStorage.getItem('position') || '');
    
        
        
    }, []); 
    
 



    const getArabicPosition = (position) => {
        switch (position) {
            case 'HeadOfDepartment':
                return 'مدير قسم';
            case 'HeadOfSection':
                return 'رئيس شعبة';
            case 'RegularEmployee':
                return 'موظف';
            default:
                return position; // Return the original if no match
        }
    };


    const handleButtonClick = () => {
        setShowOverlay(true);
    };

    const handleOverlayClose = () => {
        setShowOverlay(false);
        setCurrentPassword('');
        setNewPassword('');
        setFormError('');
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_API_BASE_URL}/api/account/update-password`,
                {
                    currentPassword,
                    newPassword
                },
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
    
            if (response.status === 200 || response.status === 204) {
                handleOverlayClose();
                // Optionally, add success feedback here
            }
        } catch (error) {
            console.error('Password update error:', error);
    
            // Extract the error message from the response body if available
            const errorMessage = error.response?.data?.message || 'An error occurred while updating the password.';
            setFormError(errorMessage);
        }
    };
    
    




  return (
    <div className="user-list-container">
    <div className="header2">
        <div className="header-content">
            <LuUserCircle2 alt="Icon" className="header-icon" />
            <h1 className="header-text2">الحساب الشخصي</h1>
        </div>
       
    </div>
    <div className="detail-info">

<p className="user-info-item"><strong className="user-info-label">اسم المستخدم:</strong> <span className="user-info-value">{userName}</span></p>
<p className="user-info-item"><strong className="user-info-label">البريد الالكتروني:</strong> <span className="user-info-value">{email}</span></p>  
<p className="user-info-item"><strong className="user-info-label">اسم الموظف:</strong> <span className="user-info-value">{employeeName}</span></p>
<p className="user-info-item"><strong className="user-info-label">عنوان الموظف:</strong> <span className="user-info-value">{employeeTitle}</span></p>
<p className="user-info-item"><strong className="user-info-label">اسم القسم:</strong> <span className="user-info-value">{departmentName}</span></p>
{sectionName && (
    <p className="user-info-item"><strong className="user-info-label">اسم الشعبة:</strong> <span className="user-info-value">{sectionName}</span></p>
)}
<p className="user-info-item"><strong className="user-info-label">المنصب:</strong> <span className="user-info-value">{getArabicPosition(position)}</span></p>

<button className='password-button' onClick={handleButtonClick} >تغير كلمة المرور</button>

</div>

{showOverlay && (
                <div className="edit-overlay">
                    <div className="password-form">
                        <h2>تغيير كلمة المرور</h2>
                       
                            
                                <label>كلمة المرور الحالية : </label>
                                <input
                                    type="text"
                                    
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                    required
                                />
                            
                            
                                <label>كلمة المرور الجديدة  :</label>
                                <input
                                    type="text"
                                    
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            
                            {formError && <p className="form-error">{formError}</p>}
                            <div className="password-actions">
                                    <button className="save-button" onClick={handleFormSubmit}>تحديث</button>
                                    <button className="cancel-button" onClick={handleOverlayClose}>إلغاء</button>
                                </div>
                        
                    </div>
                </div>
            )}

    </div>
  )
}

export default Userinfo