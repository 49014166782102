import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './components/dashboard'; 
import Login from './components/login'; 
import Form from './components/form'; 
import Income from './pages/income'; 
import Users from './pages/users'; 
import Notcomplete from './pages/notcomplete'; 
import Outbox from './pages/outbox';
import Delatory from './pages/delatory';
import Archive from './pages/archive';
import Notification from './pages/notification';
import InternalMemo from './components/internalMemo';
import IssuedBook from './components/issuedBook';
import ProtectedRoute from './components/ProtectedRoute'; 
import Table from './components/Table'; 
import Userinfo from './pages/userinfo';




function App() {
  const userdepartmentName = localStorage.getItem('userdepartmentName');

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />

        <Route 
          path="/dashboard" 
          element={
            <ProtectedRoute 
              element={<Dashboard />} 
            />
          }
        >
          <Route path="income" element={<Income />} />
          <Route path="outbox" element={<Outbox />} />
          <Route path="delatory" element={<Delatory />} />
          <Route path="archive" element={<Archive />} />
          <Route path="notcomplete" element={<Notcomplete />} />
          <Route path="notification" element={<Notification />} />
          <Route path="users" element={<Users />} />
          <Route path="userinfo" element={<Userinfo />} />
        </Route>

        <Route 
          path="/form" 
          element={
            <ProtectedRoute 
              element={<Form />} 
            />
          }
        />
        <Route 
          path="/internalMemo" 
          element={
            <ProtectedRoute 
              element={<InternalMemo />} 
            />
          }
        />
        <Route 
          path="/issuedBook" 
          element={
            <ProtectedRoute 
              element={<IssuedBook />} 
            />
          }
        />
         <Route 
          path="/table" 
          element={
            <ProtectedRoute 
              element={<Table />} 
            />
          }
        />
        

        {/* Redirect to dashboard or form depending on the user's department */}
        <Route
          path="*"
          element={
            userdepartmentName === 'ادخال البيانات' ? (
              <Navigate to="/form" />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
