import React from 'react'
import upImg from '../images/upload.svg';



const outbox = () => {
  return (
    <div className="user-list-container">
    <div className="header2">
        <div className="header-content">
            <img src={upImg} alt="Icon" className="header-icon" />
            <h1 className="header-text2">الصادر </h1>
        </div>
       
    </div>
    </div>
  )
}

export default outbox