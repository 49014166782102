import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './card.css';
import logoImage from '../images/login2.png';

const Card = ({ formId }) => {
  const [forwardNotes, setForwardNotes] = useState([]);

  useEffect(() => {
    const fetchForwardNotes = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/${formId}`, {
          headers: {
            'Authorization': `Bearer ${token}`
            
          }
        });

        const forwardNotesData = response.data.forwardNotes.map(note => ({
          sender: note.sender,
          senderTitle: note.senderTitle,
          to: note.to,
          note: note.note,
          forwardDate: new Date(note.forwardDate).toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          })
        }));
        setForwardNotes(forwardNotesData);
      } catch (error) {
        console.error('Error fetching forward notes:', error);
      }
    };


    fetchForwardNotes();
  }, [formId]);

  return (
    <div className="card-container">
      <div className='linecard'></div>
      {forwardNotes.map((card, index) => (
        <div key={index} className="card">
          <div className="card-datetime">{card.forwardDate}</div>
          <div className="card-header">
            <div className="card-header-content">
              <div>
                <div className="card-username">{card.sender}</div>
                <div className="card-userrole">{card.senderTitle}</div>
              </div>
            </div>
          </div>
          <div className="card-transfer">
            <div className="transfer-label">تحويل الى</div>
            <div className="transfer-to">{card.to}</div>
          </div>
          <div className="card-message">
            {card.note}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Card;
