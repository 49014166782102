import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './form.css';
import { useNavigate } from 'react-router-dom';
import logoImage from '../images/login2.png';
import fileImg from '../images/file.png';
import submitImg from '../images/submit.png';
import profileImage from '../images/profile.webp';

const Form = () => {
    const [inputData, setInputData] = useState({
        Sender: '',
        Topic: '',
        Number: '',
        Date: '',
        Note: '',
        Priority: 'Secret',
        documentUrl: 'tttt', 
    });
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/table?employeeName=${encodeURIComponent(employeeName)}&employeeTitle=${encodeURIComponent(employeeTitle)}`);
      };
      


    const [formFile, setformFile] = useState(null);
    const [isSubmitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');
    const [isFileValid, setIsFileValid] = useState(true);
    const [fileChosen, setFileChosen] = useState(false);

    const [employeeName, setEmployeeName] = useState('');
    const [employeeTitle, setEmployeeTitle] = useState('');


    useEffect(() => {
        const storedName = localStorage.getItem('employeeName');
        const storedTitle = localStorage.getItem('employeeTitle');
        if (storedName) setEmployeeName(storedName);
        if (storedTitle) setEmployeeTitle(storedTitle);
    }, []);


    

    const labelToPropertyMap = {
        'جهة الارسال': 'Sender',
        'الموضوع': 'Topic',
        'العدد': 'Number',
        'التاريخ': 'Date',
        'الملاحظات': 'Note',
        'الاولوية': 'Priority'
    };

    const priorityOptions = {
        'سري': 'Secret',
        'عادي': 'Normal',
        'عاجل جدا': 'VeryUrgent',
        'عاجل': 'Urgent',
        'سري وشخصي': 'PersonalSecret'
    };

    const handleChange = (e) => {
        const property = labelToPropertyMap[e.target.name];
        setInputData({ ...inputData, [property]: e.target.value });
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFileChosen(true);
    
        if (selectedFile) {
            const validTypes = [
                'application/pdf',
                'application/msword', // .doc
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
                'application/vnd.ms-excel', // .xls
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // .xlsx
            ];
    
            if (validTypes.includes(selectedFile.type)) {
                setformFile(selectedFile);
                setIsFileValid(true);
            } else {
                setformFile(null);
                setIsFileValid(false);
            }
        } else {
            setformFile(null);
            setIsFileValid(false);
        }
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!isFileValid) {
            setError('Please upload a valid PDF file.');
            return;
        }
        try {
            const formData = new FormData();
            formData.append('Sender', inputData.Sender);
            formData.append('Topic', inputData.Topic);
            formData.append('Number', inputData.Number);
            formData.append('Date', inputData.Date);
            formData.append('Note', inputData.Note);
            formData.append('Priority', inputData.Priority); // Already converted to English in inputData

            if (formFile) {
                formData.append('formFile', formFile);
            }

            if (inputData.documentUrl) {
                formData.append('DocumentUrl', inputData.documentUrl);
            }
            console.log('FormData:', Object.fromEntries(formData.entries()));


            const token = localStorage.getItem('token');

            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/forms`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (response.status === 200 || response.status === 201) {
                setSubmitted(true);
                
            } else {
                console.error('Failed to submit form:', response);
            }
        } catch (error) {
            console.error('Form submission error:', error);
            setError('Form submission failed. Please try again.');
        }
    };



  

    const handleLogout = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/account/logout`, null, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
            localStorage.removeItem('token');
            localStorage.removeItem('employeeName');
            localStorage.removeItem('employeeTitle');
            window.location.href = '/login'; // Redirect to login page
        } catch (error) {
            console.error('Logout error:', error);
        }
    };

    return (
        <div className="form-container">
            {!isSubmitted ? (
                <>
                    <div className="top-bar">
                        <div className="logo-container">
                            <img src={logoImage} alt="Logo" className="logo" />
                            
                            
                        </div>
                        <div className="user-profile">
                            <img src={profileImage} alt="User Profile" className="profile-pic"/>
                            <div className="user-info">
                                <p>{employeeName || 'الاسم الثلاثي'}</p>
                                <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
                                
                            </div>
                           
                        </div>
                    </div>
                    <div className='side'>
                    <button className='archform' onClick={handleNavigate}>سجل الادخالات</button>
                    <button className='archform' onClick={handleLogout}>تسجيل الخروج </button>


                    </div>

                    <form onSubmit={handleSubmit} className='form'>
                    
                   


                        <h1 className='formh1'>انشاء بريد الكتروني</h1>


                        {Object.keys(labelToPropertyMap).map((label) => (
                            <div className="form-group" key={label}>
                                <label className='labelform'>{label}</label>
                                {label === 'الاولوية' ? (
                                    <select
                                        className="Property"
                                        name={label}
                                        value={inputData.Priority}
                                        onChange={handleChange}
                                    >
                                        {Object.entries(priorityOptions).map(([arabicLabel, englishValue]) => (
                                            <option key={englishValue} value={englishValue}>
                                                {arabicLabel}
                                            </option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        className='input-form'
                                        type={label === 'التاريخ' ? 'text' : 'text'}
                                        name={label}
                                        value={inputData[labelToPropertyMap[label]]}
                                        onChange={handleChange}
                                    />
                                )}
                            </div>
                        ))}

                        <div className="form-group">
                            <label
                                htmlFor="file-upload"
                                className={`upload-button ${fileChosen ? (isFileValid ? 'valid-border' : 'invalid-border') : ''}`}
                            >
                                <img src={fileImg} alt="Upload" />
                                {fileChosen ? 'تم ارفاق المستند' : 'إرفاق صورة المستند'}
                            </label>
                            <input
                                id="file-upload"
                                type="file"
                                onChange={handleFileChange}
                                accept=".pdf, .doc, .docx, .xls, .xlsx"
                                className="file-input"
                            />
                        </div>

                        <div className="form-group">
                            <button className="submit-button" type="submit">إنشاء</button>
                        </div>
                    </form>

                    {error && <div className="error-message">{error}</div>}
                </>
            ) : (
                <>
                    <div className="top-bar">
                        <div class="logo-container">
                            <img src={logoImage} alt="Logo" className="logo" />
                        </div>
                        <div className="user-profile">
                            <img src={profileImage} alt="User Profile" className="profile-pic" />
                            <div className="user-info">
                                <p>{employeeName || 'الاسم الثلاثي'}</p>
                                <span className="profiletext">{employeeTitle || 'موظف ادخال البيانات'}</span>
                            </div>
                        </div>
                    </div>

                    <div className="success-message">
                        <div className="success-icon">
                            <img src={submitImg} alt="Success" />
                        </div>
                        <h1 className='formh2'>تم انشاء البريد الالكتروني بنجاح</h1>
                        <button onClick={() => setSubmitted(false) & window.location.reload() } className="success-button">تم</button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Form;
