import React, { useState } from 'react';
import './MultiSelectDropdown.css'; // Create this file for styling

const MultiSelectDropdown = ({ options, selectedValues, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        onChange(e.target.checked ? [...selectedValues, value] : selectedValues.filter(item => item !== value));
    };

    return (
        <div className="multi-select-dropdown">
            {/* Display the selected items as a paragraph */}
            

            <button className="dropdown-toggle" onClick={toggleDropdown}>
            <p className="selected-items-p ">
                {selectedValues.length > 0 ? selectedValues.join(' , ') : ""}
            </p>
                <svg className={`arrow-icon ${isOpen ? 'open' : ''}`} width="15" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 10l5 5 5-5H7z" fill="#000" />
                </svg>
               
            </button>

            {isOpen && (
                <div className="dropdown-menu">
                    {options.map((option, index) => (
                        <label key={index} className="dropdown-item">
                            <input
                                type="checkbox"
                                value={option}
                                checked={selectedValues.includes(option)}
                                onChange={handleCheckboxChange}
                            />
                            {option}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default MultiSelectDropdown;
