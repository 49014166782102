import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './form.css';
import axios from 'axios';
import logoImage from '../images/login2.png';
import profileImage from '../images/profile.webp';

const Table = () => {
  const location = useLocation();
  const navigate = useNavigate(); // Use navigate for redirection
  const queryParams = new URLSearchParams(location.search);

  const employeeName = queryParams.get('employeeName') || 'الاسم الثلاثي';
  const employeeTitle = queryParams.get('employeeTitle') || 'موظف ادخال البيانات';

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/forms/GetMyEntry`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  const priorityMap = {
    'Secret': 'سري',
    'Normal': 'عادي',
    'Urgent': 'عاجل',
    'VeryUrgent': 'عاجل جدا',
    'PersonalSecret': 'سري وشخصي'
  };

  const convertPriority = (priority) => priorityMap[priority] || priority;

  // Pagination logic
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = data.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="form-container">
      <div className="top-bar">
        <div className="logo-container">
          <img src={logoImage} alt="Logo" className="logo" /><br /><br />
        </div>
        <div className="user-profile">
          <img src={profileImage} alt="User Profile" className="profile-pic" />
          <div className="user-info">
            <p>{employeeName}</p>
            <span className="profiletext">{employeeTitle}</span>
          </div>
        </div>
      </div>

      <div className="table-container">
        <table className="data-table">
          <thead>
            <tr>
              <th>جهة الارسال</th>
              <th>الموضوع</th>
              <th>العدد</th>
              <th>التاريخ</th>
              <th>الاولوية</th>
              <th>الملاحظات</th>
            </tr>
          </thead>
          <tbody>
            {currentEntries.map((entry, index) => (
              <tr key={index}>
                <td>{entry.sender}</td>
                <td>{entry.topic}</td>
                <td>{entry.number}</td>
                <td>{entry.date}</td>
                <td>{convertPriority(entry.priority)}</td>
                <td>{entry.note}</td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Pagination */}
        <div className="pagination">
          {[...Array(Math.ceil(data.length / entriesPerPage)).keys()].map((number) => (
            <button
              key={number + 1}
              onClick={() => paginate(number + 1)}
              className={currentPage === number + 1 ? 'active' : ''}
            >
              {number + 1}
            </button>
          ))}
        </div>
      </div>

      <div className="back-button-container">
        <button className="back-button" onClick={() => navigate('/form')}>رجوع</button>
      </div>
    </div>
  );
};

export default Table;
